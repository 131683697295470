import * as React from 'react'
import {withRouter, WithRouterProps} from '$common/utils'
import {compose} from 'redux'

type Props = WithRouterProps

type State = {}

class PlaygroundPage extends React.Component<Props, State> {
  render() {
    return <div>Ops</div>
  }
}

export default compose(withRouter)(PlaygroundPage)
